"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OdaCosts = void 0;
exports.OdaCosts = {
    licenseCost: 300,
    permitCost: 71,
    lateFeePercent: 0.1,
    permitReinstatementFee: 300,
    permitTransferFee: 5,
    maxPermitTransferFee: 100
};
