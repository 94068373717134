import React, { useCallback, useMemo, useState, useEffect } from "react";
import { ButtonWithIcon } from "../common/ButtonWithIcon";
import { CustomCard } from "../common/CustomCard";
import { IIlegalSign, InternalRole, Theme, IllegalSignResolution, NoticeRemovedBy, Rights } from "oda-shared";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/RootReducer";
import { useSelector, useDispatch } from "react-redux";
import { getIllegalSigns, createIllegalSign } from "../../redux/slices/IllegalSignSlice";
import {dateUtils} from "oda-shared";
import { State, process } from "@progress/kendo-data-query";
import {
  customCellRender,
  customHeaderRender,
  gridDataState,
  gridDate,
  gridLink,
  gridPageable,
} from "../common/grid/customCellRender";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { GridCellProps, GridPageChangeEvent, Grid, GridColumn } from "@progress/kendo-react-grid";
import { format } from "date-fns";

interface IProps {
  filterToMe?: boolean;
}

const IllegalSigns: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const illegalSigns = useSelector((state: RootState) => state.illegalSignReducer.illegalSigns);
  const [dataState, setDataState] = React.useState<State>(gridDataState("tempNumber"));
  const [pageSizeValue, setPageSizeValue] = useState(dataState.take);

  const user = useSelector((state: RootState) => state.userReducer.user);

  //const filterToMe = !Rights.canViewIllegalSigns(user) || user?.isFieldConsultantInspector || user?.isFieldConsultantManager;
  const filterToMe = true;
  const data = illegalSigns.map(m => {
    return {
      ...m,      
      foundDate: (m.foundDate ? new Date(m.foundDate) : undefined) as any,
      statusDate: (m.statusDate ? new Date(m.statusDate) : undefined) as any
    };
  });

  useEffect(() => {
    dispatch(getIllegalSigns(filterToMe));
  }, [dispatch]);

  const addIllegalSign = () => {
    dispatch(
      createIllegalSign(
        {
          resolution: IllegalSignResolution.NotApplicable,
          removedBy: NoticeRemovedBy.NotApplicable,
        } as any,
        id => {
          navigate("/illegalSigns/" + id);
        },
      ),
    );
  };

  const illegalSignCellRender = (e: GridCellProps) => gridLink(e, `/IllegalSigns/${e.dataItem._id}`, e.dataItem.number);

  const onPageChange = (e: GridPageChangeEvent) => {
    const targetEvent = e.targetEvent as PagerTargetEvent;
    const take = targetEvent.value === "All" ? illegalSigns.length : e.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setDataState({ ...dataState, skip: e.page.skip, take });
  };

  return (
    <div className={props.filterToMe ? "" : "container"}>
      <CustomCard headerText="Illegal Signs" collapsible={props.filterToMe}>
        {!props.filterToMe &&
        (Rights.canCreateEditIllegalSigns(user)) ? (
          <div className="row">
            <div className="col text-right">
              <ButtonWithIcon
                buttonText="Illegal Sign"
                buttonType="primary"
                titleText="Add Illegal Sign"
                onClick={addIllegalSign}
                icon="plus"
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        <Grid
          sortable={true}
          filterable
          pageable={gridPageable(pageSizeValue)}
          data={process(data, dataState)}
          {...dataState}
          onDataStateChange={e => {
            setDataState(e.dataState);
          }}
          cellRender={customCellRender}
          headerCellRender={customHeaderRender}
          onPageChange={onPageChange}>
          <GridColumn field="number" title="Tracking #" cell={illegalSignCellRender} />
          <GridColumn field="foundDate" title="Date Added" cell={e => gridDate(e.dataItem.foundDate)} filterable={false}/>
          <GridColumn field="status" title="Status" />
          <GridColumn field="statusDate" title="Status Date"  cell={e => gridDate(e.dataItem.statusDate)}/>
        </Grid>
      </CustomCard>
    </div>
  );
};

export default IllegalSigns;
