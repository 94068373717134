import React from "react";
import { CustomHeaderCell } from "./customHeaderCell";
import { FocusableCell } from "./focusableCell";
import { GridCellProps } from "@progress/kendo-react-grid";
//import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { State, SortDescriptor } from "@progress/kendo-data-query";
import { IGridSort, IGridFilter } from "oda-shared";
import {dateUtils} from "oda-shared";
import { getCurrencyFormat } from "../../../utils/FormUtils";
import { NumericTextBox, NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { CustomTitledHeaderCell } from "./customTitledHeaderCell";

export const customCellRender = (e: any) => {
  return <FocusableCell tdProps={e.props} />;
};

export const customHeaderRender = (e: any) => {
  return <CustomHeaderCell thProps={e.props} />;
};

export const customTitledHeaderRender = (e:any) => {  
  return <CustomTitledHeaderCell thProps={e.props}/>
}

export const gridServerSort = (gridSort: SortDescriptor[] | undefined): IGridSort | undefined =>
  gridSort && gridSort.length > 0 ? { field: gridSort[0].field, dir: gridSort[0].dir } : undefined;

const selectFilterProps = (gridFilter: any) => {
  return { field: (gridFilter as any).field as string, value: (gridFilter as any).value as string };
};

export const gridServerFilter = (gridFilter: Array<any> | undefined): IGridFilter[] | undefined =>
  gridFilter && gridFilter.length > 0 ? gridFilter.map(selectFilterProps) : undefined;

export const getGridFilters = (dataState: State) => {
  return {
    skip: dataState.skip ?? 0,
    take: dataState.take ?? 10,
    sort: gridServerSort(dataState.sort),
    filter: gridServerFilter(dataState.filter?.filters),
  };
};

export const gridServerPageSizes = () => [10, 25, 50, 100];

export const gridServerPageable = (pageSizeValue: number | undefined = 10) => {
  return {
    buttonCount: 4,
    pageSizes: gridServerPageSizes(),
    pageSizeValue,
  };
};

export const gridPageSizes = () => [10, 25, 50, "All"];

export const gridDataState = (field: string, skip: number = 0, take: number = 10, dir: "asc" | "desc" = "asc") => {
  return {
    skip,
    take,
    sort: [{ field, dir }],
  };
};

export const gridPageable = (pageSizeValue: number | undefined = 10) => {
  return {
    buttonCount: 4,
    pageSizes: gridPageSizes(),
    pageSizeValue,
  };
};

export const gridLink = (e: GridCellProps, linkTo: string | undefined, linkText: string, openInNewWindow?: boolean) => {
  return linkTo ? (
    <td colSpan={e.colSpan} style={e.style}>
      <Link style={{ color: "#006bd6" }} to={linkTo} target={openInNewWindow ? "_blank" : ""}>
        {linkText}
      </Link>
    </td>
  ) : (
    <td colSpan={e.colSpan} style={e.style}>
      <span>{linkText}</span>
    </td>
  );
};

export const gridBoolean = (e: GridCellProps, val: boolean, valIfTrue?: string, valIfFalse?: string) => {
  return (
    <td colSpan={e.colSpan} style={e.style}>
      <span>{val ? valIfTrue ?? "Yes" : valIfFalse ?? "No"}</span>
    </td>
  );
};

export const gridDate = (val: any) => {
  //return <td>{dateUtils.formatDateWithNoTimeZone(val)}</td>;
  return gridDateWithOffset(val);
};

export const gridDateWithOffset = (val: any) => {
  return <td>{dateUtils.formatDateWithNoTimeZoneAddOffset(val)}</td>;
};

export const gridCurrency = (val: any) => {
  return <td>{getCurrencyFormat(val)}</td>;
};

export const NumericMonthLimitFilterCell = props => {
  let minTextBox;

  const equal = (current, { min }) => min === null || current == min;

  const onChange = (event: NumericTextBoxChangeEvent) => {
    props.onChange({
      value: { min: minTextBox.value },
      operator: equal,
      syntheticEvent: event.syntheticEvent,
    });
  };

  const onClearButtonClick = event => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });
  };

  const value = props.value || null;

  return (
    <div className="col-lg-11">
      <NumericTextBox
        value={value && value.min}
        ref={numeric => {
          minTextBox = numeric;
        }}
        min={1}
        max={12}
        onChange={onChange}
      />
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-button k-button-md k-rounded-md k-button-solid k-button-solid-base-icon k-clear-button-visible"
        title="Clear"
        disabled={!value}
        onClick={onClearButtonClick}>
        <span className="k-icon k-i-filter-clear" />
      </button>
    </div>
  );
};
