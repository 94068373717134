import * as shoppingCartApi from "../../apis/payment/ShoppingCartApi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IShoppingCartItem, IShoppingCart } from "oda-shared";

type SliceState = {
  items: IShoppingCartItem[];
  cartLoaded: boolean;
};

const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState: {
    items: [],
    cartLoaded: false,
  } as SliceState,
  reducers: {
    updateItems: (state: SliceState, action: PayloadAction<IShoppingCartItem[]>) => {
      state.items = action.payload;
      state.cartLoaded = true;
      return state;
    },
    updateItem: (state: SliceState, action: PayloadAction<IShoppingCartItem>) => {
      for (let i = 0; i < state.items.length; i++) {
        if (state.items[i]._id === action.payload._id) {
          state.items[i] = action.payload;
        }
      }
      return state;
    },
    addItem: (state: SliceState, action: PayloadAction<IShoppingCartItem>) => {
      if (state.items.findIndex(item => item._id.toString() === action.payload._id.toString())<0){
        state.items.push(action.payload);
      }
      return state;
    },
    addItems: (state: SliceState, action: PayloadAction<IShoppingCartItem[]>) => {
      for (var newItem of action.payload){
        const newId = newItem._id;
        if (state.items.findIndex(item => item._id.toString() === newId)<0){
          state.items.push(newItem);
        }
      }
      return state;
    },
    deleteItems: (state: SliceState, action: PayloadAction<string[]>) => {
      //debugger;
      state.items = state.items.filter(i => !action.payload.includes(i._id.toString()));
      return state;
    },
    deleteItem: (state: SliceState, action: PayloadAction<string>) => {
      state.items = state.items.filter(i => i._id !== action.payload);
      return state;
    },
  },
});

export const { updateItems, updateItem, addItem, addItems, deleteItem, deleteItems } = shoppingCartSlice.actions;
export default shoppingCartSlice.reducer;

export const fetchShoppingCart = () => async (dispatch: any) => {
  shoppingCartApi
    .getShoppingCart()
    .then((shoppingCart: IShoppingCart) => {
      dispatch(updateItems(shoppingCart?.items || []));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const updateShoppingCartItem = (item: IShoppingCartItem) => async (dispatch: any) => {
  shoppingCartApi
    .updateItem(item)
    .then((item: IShoppingCartItem) => {
      dispatch(updateItem(item));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const addRenewalItems = (
  accountId: string,
  dateChanger: string | Date | undefined | null,
  successCallback: () => void,
  failureCallback: () => void,
) => async (dispatch: any) => {
  console.log(`Adding to shopping cart: renewals for ${accountId}`);
  shoppingCartApi
    .addRenewalItems(accountId, dateChanger)
    .then((items: IShoppingCartItem[]) => {
      dispatch(addItems(items));
      successCallback();
    })
    .catch((error: any) => {
      console.log(error);
      failureCallback();
    });
};

export const addShoppingCartItem = (
  item: IShoppingCartItem,
  successCallback: () => void,
  failureCallback: () => void,
) => async (dispatch: any) => {
  console.log(`Adding to shopping cart: ${JSON.stringify(item)}`);
  shoppingCartApi
    .addItem(item)
    .then((items: IShoppingCartItem[]) => {
      dispatch(addItems(items));
      successCallback();
    })
    .catch((error: any) => {
      console.log(error);
      failureCallback();
    });
};
export const deleteRenewalItems = (accountId: string, successCallback: () => void, failureCallback: () => void) => async (
  dispatch: any,
) => {
  //debugger;
  shoppingCartApi
    .deleteRenewalItems(accountId)
    .then((ids: string[]) => {
      dispatch(deleteItems(ids));
      successCallback();
    })
    .catch((error: any) => {
      console.log(error);
      failureCallback();
    });
};
export const deleteShoppingCartItem = (id: string, successCallback: () => void, failureCallback: () => void) => async (
  dispatch: any,
) => {
  //debugger;
  shoppingCartApi
    .deleteItem(id)
    .then((ids: string[]) => {
      dispatch(deleteItems(ids));
      successCallback();
    })
    .catch((error: any) => {
      console.log(error);
      failureCallback();
    });
};
