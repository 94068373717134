"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPermitExpired = exports.isLicenseExpired = exports.canRenewLicense = exports.canRenewPermit = exports.isTodayWithinLateFeeWindow = exports.isTodayWithinRenewalPeriod = exports.isDateWithinRenewalPeriod = void 0;
var dateUtils = __importStar(require("../utils/DateUtils"));
var PermitStatus_1 = require("../models/enums/PermitStatus");
var dateChangerService = __importStar(require("../services/DateChangerService"));
var LicenseStatus_1 = require("../models/enums/LicenseStatus");
//Workflow for renewals. Using year 2024 as an example.
//Sept 15th 2024, the renewal period begins.    
//Batch job sets the Renewal Date in the db to Jan 15th, 2025, and last day to renew March 5th (or closest business day)
//Any permits renewed in this time period have their expiration date set to Jan 15th 2026.    
//On Jan 15th 2025, a batch job runs to expire all Active permits where their expiration date is the Renewal Date (Jan 2025)
//On March 5th, 2025, renewals are no longer accepted via ODA and must be done on paper.     
//And on Sept 15th, 2025, the cycle repeats.
exports.isDateWithinRenewalPeriod = function (renewalDateRecords, checkDate) {
    var renewalDate = new Date(renewalDateRecords.renewalDate);
    //Earliest renewal date is September 15th before the current renewal date
    var earliestRenewalDate = new Date(renewalDate.getFullYear() - 1, 8, 15);
    //Latest renewal date is business day closest to March 5th after renewal date
    var latestRenewalDate = new Date(renewalDateRecords.lastDateToRenewWithoutPetition);
    if (!checkDate || isNaN(checkDate.getTime())) {
        return false;
    }
    checkDate.setHours(0, 0, 0, 0);
    latestRenewalDate.setHours(0, 0, 0, 0);
    return checkDate >= earliestRenewalDate && checkDate <= latestRenewalDate;
};
exports.isTodayWithinRenewalPeriod = function (renewalDateRecords, dateChangerDate) {
    var now = dateChangerService.getWorkingAsDate(dateChangerDate);
    return exports.isDateWithinRenewalPeriod(renewalDateRecords, now);
};
exports.isTodayWithinLateFeeWindow = function (dateChangerDate) {
    var now = dateUtils.removeTimeFromDate(dateChangerService.getWorkingAsDate(dateChangerDate));
    //Late fees are charged between Jan 16 and up until July 1st for expired permits (probably?)
    var lateFeeBegins = new Date(now.getFullYear(), 0, 16);
    var lateFeeEnds = new Date(now.getFullYear(), 6, 1);
    return now > lateFeeBegins && now < lateFeeEnds;
};
exports.canRenewPermit = function (permit, renewalDateRecords, dateChangerDate) {
    var expirationDate = dateUtils.removeTimeFromDate(new Date(permit.expirationDate));
    console.log("Checking permit " + permit.permitNumber + ". Status: " + permit.status + " and expiration date " + expirationDate);
    if (!exports.isTodayWithinRenewalPeriod(renewalDateRecords, dateChangerDate)) {
        console.log("Today is not within renewal period.");
        return false;
    }
    if (exports.isDateWithinRenewalPeriod(renewalDateRecords, expirationDate) && (permit.status === PermitStatus_1.PermitStatus.Active || permit.status === PermitStatus_1.PermitStatus.Expired)) {
        //Active permits can be renewed as long as it's within the renewal period and it hasn't been renewed yet
        console.log("Active, within renewal date, and not renewed yet");
        return true;
    }
    return false;
};
exports.canRenewLicense = function (license, renewalDateRecords, dateChangerDate) {
    var expirationDate = dateUtils.removeTimeFromDate(new Date(license.expirationDate));
    console.log("Checking license " + license.licenseNumber + ". Status: " + license.status + " and expiration date " + expirationDate);
    if (!exports.isTodayWithinRenewalPeriod(renewalDateRecords, dateChangerDate)) {
        console.log("Today is not within renewal period.");
        return false;
    }
    if (exports.isDateWithinRenewalPeriod(renewalDateRecords, expirationDate) && (license.status === LicenseStatus_1.LicenseStatus.Active || license.status === LicenseStatus_1.LicenseStatus.Expired)) {
        return true;
    }
    return false;
};
exports.isLicenseExpired = function (license, dateChanger) {
    var now = dateUtils.removeTimeFromDate(dateChangerService.getWorkingAsDate(dateChanger));
    var expiration = dateUtils.removeTimeFromDate(license.expirationDate);
    return now >= expiration;
};
exports.isPermitExpired = function (permit, dateChanger) {
    var now = dateUtils.removeTimeFromDate(dateChangerService.getWorkingAsDate(dateChanger));
    var expiration = dateUtils.removeTimeFromDate(permit.expirationDate);
    return now >= expiration;
};
