import {
  IApplicableSection,
  IllegalSignTaskType,
  Inspection270DayResult,
  InternalRole,
  TaskAssignmentStatus,
  TaskType,
  Rights,
} from "oda-shared";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/RootReducer";
import { ButtonWithIcon } from "../common/ButtonWithIcon";
import { CustomCard } from "../common/CustomCard";
import EnumToOptions from "../common/EnumOptions";
import InspectorOptions from "../common/InspectorOptions";
import {
  getNoticeTaskAssignments,
  getViolationTaskAssignments,
  updateTaskAssignment,
  addTaskAssignment,
  loadCurrentTaskAssignment,
  getTaskAssignment,
  getIllegalSignTaskAssignments,
  deleteTaskItem,
  getFindingTaskAssignments,
  getLitigationTaskAssignments,
  getPermitTaskAssignments,
  updateLoadedTaskAssignment,
} from "../../redux/slices/TaskAssignmentSlice";
import { FilePond } from "react-filepond";
import { taskAssignmentUrl, documentUrl } from "../../apis/DocumentApi";
import DocumentTypeSelector from "../common/DocumentTypeSelector";
import EnumRadioButton from "./../common/EnumRadioButton";
import { getFieldConsultantInspectors } from "../../redux/slices/FieldConsultantsSlice";
import { useNavigate, useParams } from "react-router-dom";
//import useHasInternalRole from "../../hooks/useHasInternalRole";
import {dateUtils} from "oda-shared";
import DocumentLink from "../common/DocumentLink";
import { current } from "@reduxjs/toolkit";
import { getFinding, setFindingTasks } from "../../redux/slices/FindingSlice";
import { addDays, addMonths, format } from "date-fns";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";
import useInspectorProfiles from "../../hooks/useInspectorProfiles";
interface IProps {
  violationId?: string;
  noticeId?: string;
  findingId?: string;
  illegalSignId?: string;
  litigationId?: string;
  permitId?: string;
  isDisabled?: boolean;
  taskId?: string;
  canCreateTasks?: boolean;
  canCreateVOC?: boolean;
}

const TaskAssignment: React.FC<IProps> = (props: IProps) => {
  const { violationId, noticeId, illegalSignId, findingId, litigationId, permitId } = props;
  const taskAssignments = useSelector((state: RootState) => state.taskAssignmentReducer.taskAssignments);
  const currentTaskAssignment = useSelector((state: RootState) => state.taskAssignmentReducer.currentTaskAssignment);
  const currentPermit = useSelector((state: RootState) => state.permitReducer.currentPermit);
  const currentIllegalSign = useSelector((state: RootState) => state.illegalSignReducer.currentIllegalSign);
  const currentNotice = useSelector((state: RootState) => state.noticeReducer.notice);
  const currentFinding = useSelector((state: RootState) => state.findingReducer.currentFinding);
  const currentViolation = useSelector((state: RootState) => state.violationReducer.currentViolation);
  const currentLitigation = useSelector((state: RootState) => state.litigationReducer.litigation);
  const dispatch = useDispatch();
  const [taskType, setTaskType] = useState<string>("");
  const navigate = useNavigate();
  const [isConsultantTask, setisConsultantTask] = useState<boolean>(false);
  const [assignedInspectorId, setAssignedInspectorId] = useState<string>("");
  const [assignedConsultantInspectorId, setAssignedConsultantInspectorId] = useState<string>("");
  const [taskStatus, setTaskStatus] = useState("");
  const [voidOfCopy, setVoidOfCopy] = useState();
  const [comment, setComment] = useState<string>("");
  const [actionRequired, setActionRequired] = useState<string>("");
  const [dueDate, setDueDate] = useState<string>();
  const [inspectionResult, setInspectionResult] = useState<string>("");
  const [documentType, setDocumentType] = useState<string>("");
  const filePondRef = useRef(null);
  const { fieldConsultantInspectors } = useSelector((state: RootState) => state.fieldConsultantsReducer);
  const { taskIdParam } = useParams();
  const [taskId, setTaskId] = useState<string>("");
  const user = useSelector((state: RootState) => state.userReducer.user);
  const isFieldConsultantManager = useSelector((state: RootState) => state.userReducer!.user!.isFieldConsultantManager);
  const inspectorsUserProfiles = useInspectorProfiles();
  const isTaskComplete = currentTaskAssignment?.taskStatus === TaskAssignmentStatus.Complete;

  const canChangeConsultantInspector = Rights.canChangeTaskConsultantInspector(user);
  // const canChangeConsultantInspector =
  //   useHasInternalRole([
  //     InternalRole.FdotAdmin,
  //     InternalRole.OfficeStaff,
  //     InternalRole.OfficeSupervisor,
  //     InternalRole.FdotInspector,
  //     InternalRole.ManagementReviewer,
  //     InternalRole.FieldSupervisor,
  //   ]) || isFieldConsultantManager;

  const canEditTask =
    !props.isDisabled &&
    (Rights.canEditAllTasks(user) ||
      (Rights.canEditMyTasks(user) && currentTaskAssignment?.assignedToId === user?._id) ||
      (currentTaskAssignment?.isConsultantTask &&
        (isFieldConsultantManager || currentTaskAssignment?.assignedConsultantInspectorId === user?._id)));

  //Can't edit due date for field review or 270 day inspection tasks.
  const canEditDueDate =
    Rights.canEditAllTasks(user) &&
    currentTaskAssignment?.taskStatus !== TaskAssignmentStatus.Complete &&
    currentTaskAssignment?.taskType !== TaskType.FieldReview &&
    currentTaskAssignment?.taskType !== TaskType.Inspection270Day;

  
  const displayTaskType = () => {
    return true;
    //return props.findingId || props.illegalSignId || props.litigationId;
  };

  useEffect(() => {
    dispatch(getFieldConsultantInspectors());
  }, [dispatch]);

  useEffect(() => {
    
    const newId = props.taskId ?? taskIdParam ?? "";
    setTaskId(newId);
  }, [dispatch, props.taskId, taskIdParam]);

  useEffect(() => {
    if (taskId && taskAssignments && taskAssignments.length > 0) {
      const taskAssignment = taskAssignments.find(x => x._id === taskId);
      if (taskAssignment) {
        dispatch(loadCurrentTaskAssignment(taskAssignment!));
      }
    }
  }, [dispatch, taskId, taskAssignments]);

  useEffect(() => {
    if (noticeId) {
      dispatch(getNoticeTaskAssignments(noticeId));
    }
  }, [dispatch, noticeId, currentNotice]);

  useEffect(() => {
    if (illegalSignId) {
      dispatch(getIllegalSignTaskAssignments(illegalSignId));
    }
  }, [dispatch, illegalSignId, currentIllegalSign]);

  useEffect(() => {
    if (findingId) {
      dispatch(getFindingTaskAssignments(findingId));
    }
  }, [dispatch, findingId, currentFinding]);

  useEffect(() => {
    if (violationId) {
      dispatch(getViolationTaskAssignments(violationId));
    }
  }, [dispatch, violationId, currentViolation]);

  useEffect(() => {
    if (litigationId) {
      dispatch(getLitigationTaskAssignments(litigationId));
    }
  }, [dispatch, litigationId, currentLitigation]);

  useEffect(() => {
    if (permitId) {
      dispatch(getPermitTaskAssignments(permitId));
    }
  }, [dispatch, permitId, currentPermit]);

  useEffect(() => {
    if (currentTaskAssignment) {
      
      setTaskType(currentTaskAssignment?.taskType);
      setisConsultantTask(currentTaskAssignment?.isConsultantTask);
      setAssignedInspectorId(currentTaskAssignment?.assignedInspectorId);
      setAssignedConsultantInspectorId(currentTaskAssignment?.assignedConsultantInspectorId);
      setTaskStatus(currentTaskAssignment?.taskStatus);
      setVoidOfCopy(currentTaskAssignment?.voidOfCopy);
      setComment(currentTaskAssignment?.comment);
      setActionRequired(currentTaskAssignment?.actionRequired);
      setDueDate(dateUtils.inputFieldDateFormat(currentTaskAssignment?.dueDate));
      setInspectionResult(currentTaskAssignment?.inspectionResult);
    }
  }, [dispatch, currentTaskAssignment]);

  const addNewTaskAssignment = () => {
    
    dispatch(
      addTaskAssignment(
        {
          noticeId: noticeId,
          violationId: violationId,
          taskType: props.litigationId
            ? TaskType.LitigationSupport
            : props.illegalSignId
            ? TaskType.SignRemovalWarningSticker
            : TaskType.Violation,
          illegalSignId: illegalSignId,
          litigationId: props.litigationId,
          findingId: props.findingId,
        } as any,
        (id: any) => {
          if (findingId) {
            dispatch(getFinding(findingId));
            setTaskId(id);
          }
          //navigate(`/Task/${id}`);
        },
      ),
    );
  };

  const saveTaskAssignment = () => {
    
    let newDueDate = utcToZonedTime(dueDate ?? "", "America/New_York");

    dispatch(
      updateTaskAssignment(
        {
          ...currentTaskAssignment,
          isConsultantTask,
          taskType,
          dueDate: dueDate ? newDueDate : "",
          assignedConsultantInspectorId: assignedConsultantInspectorId === "" ? null : assignedConsultantInspectorId,
          assignedInspectorId: assignedInspectorId === "" ? null : assignedInspectorId,
          taskStatus,
          voidOfCopy,
          comment,
          inspectionResult,
          actionRequired,
        } as any,
        id => {
          dispatch(getTaskAssignment(id));
          if (findingId) {
            dispatch(getFinding(findingId));
            //dispatch(getTaskAssignment(currentTaskAssignment?._id));
          }
          //navigate(`/Task/${id}`);
        },
      ),
    );
  };

  const addVOCTask = (dueDate: Date) => {
    dueDate.setHours(8, 0, 0, 0);
    dispatch(
      addTaskAssignment(
        {
          rciSection: currentPermit?.structure?.sectionNumber,
          milepost: currentPermit?.structure?.milepost,
          sideOfRoad: currentPermit?.structure?.sideOfSection,
          permitNumber1: currentPermit?.permitNumber,
          structureId: currentPermit?.structure?.structureNumber,
          county: currentPermit?.county,
          statusChange: new Date(),
          dueDate,
          findingId: currentFinding._id,
          taskType: TaskType.VOC,
        } as any,
        id => {
          dispatch(getTaskAssignment(id));
          if (findingId) {
            dispatch(getFinding(findingId));
            //dispatch(getTaskAssignment(currentTaskAssignment?._id));
          }
          //navigate(`/Task/${id}`);
        },
      ),
    );
  };

  const removeAdditionalDocument = (index: number) => {
    const documents = [...currentTaskAssignment!.documents];
    documents.splice(index, 1);
    const updatedCurrentTask = {
      ...currentTaskAssignment,
      documents: documents,
    };
    dispatch(updateTaskAssignment(updatedCurrentTask as any, () => {}));
  };

  const getVocRow = (index: number) => {
    const monthsToAdd = (index + 1) * 3;
    let rowDueDate = addMonths(new Date(currentFinding.findingDate as any), monthsToAdd);
    if (index === 3) {
      rowDueDate = addDays(rowDueDate, 1);
    }
    console.log(index);
    console.log(rowDueDate);
    let task;
    const vocTasks = currentFinding.tasks?.filter(t => t.taskType === TaskType.VOC);
    if (vocTasks && vocTasks.length > index) {
      const taskCopy = [...vocTasks];
      const orderedTaskByTaskNumber = taskCopy.sort((a, b) => {
        return a.taskNumber - b.taskNumber;
      });
      task = orderedTaskByTaskNumber[index];
      //stupidness about how dates are set at 00:00:00 UTC but displayed in -5...
      rowDueDate = new Date(task.dueDate);
      rowDueDate.setHours(rowDueDate.getHours() + 5);
    }
    

    return (
      <tr key={`follow-up-${index}`}>
        <td></td>
        <td>
          {task?.taskNumber
            ? task.taskNumber
            : props.canCreateVOC && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    addVOCTask(rowDueDate);
                  }}>
                  Create VOC Task
                </button>
              )}
        </td>
        <td>VOC Follow Up #{index + 1} </td>
        <td>{currentFinding.findingDate ? format(rowDueDate, "MM/dd/yyyy") : ""}</td>
        <td>{task?.assignedInspector?.displayName}</td>
        <td>{task?.voidOfCopyInspectionDate ? 
                format(new Date(task.voidOfCopyInspectionDate), "MM/dd/yyyy") : 
                task?.status === TaskAssignmentStatus.Complete ? 
                  format(new Date(task.statusChangeDate), "MM/dd/yyyy") : 
                  ""}</td>
        <td>{task?.voidOfCopy === true ? "Yes" : task?.voidOfCopy === false ? "No" : ""}</td>
        <td>{task?.postCardSentDate ? format(new Date(task.postCardSentDate), "MM/dd/yyyy") : ""}</td>
      </tr>
    );
  };

  return (
    <>
      {findingId && currentFinding && currentFinding.voidCopyNonComplianceSign && (
        <CustomCard headerText="Void of Copy" collapsible={true}>
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body bg-light">
                <div className="table-responsive-lg">
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Task #</th>
                        <th>Action Required</th>
                        <th>Due Date</th>
                        <th>Assigned To</th>
                        <th>Inspection Date</th>
                        <th>Void of Copy</th>
                        <th>Postcard Sent Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getVocRow(0)}
                      {getVocRow(1)}
                      {getVocRow(2)}
                      {getVocRow(3)}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </CustomCard>
      )}
      <br />
      <div className="row">
        <div className="col-lg-12">
          <CustomCard headerText="Task Assignments" collapsible={true}>
            {props.canCreateTasks && !props.isDisabled && (
              <div className="row">
                <div className="col-lg-12 text-right">
                  <ButtonWithIcon
                    icon="plus"
                    titleText="Add Task"
                    buttonText="Add Task"
                    onClick={() => addNewTaskAssignment()}
                    buttonType="primary"></ButtonWithIcon>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col table-responsive-lg">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Task Number</th>
                      {displayTaskType() ? <th>Task Type</th> : <></>}
                      <th>Task Status</th>
                      <th>Assigned To</th>
                      <th>Assigned Date</th>
                      <th>Status Change Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {taskAssignments && taskAssignments.length > 0 ? taskAssignments?.map(taskAssignment => {
                      return (
                        <tr key={taskAssignment._id}>
                          <td>
                            <button
                              className="btn btn-link"
                              type="button"
                              onClick={() => setTaskId(taskAssignment._id)}>
                              {taskAssignment.taskNumber}
                            </button>
                            {/* )} */}
                          </td>
                          {displayTaskType() ? <td>{taskAssignment.taskType}</td> : <></>}
                          <td>{taskAssignment.taskStatus}</td>
                          <td>
                            {taskAssignment.isConsultantTask
                              ? taskAssignment.assignedConsultantInspector?.displayName
                              : taskAssignment.assignedInspector?.displayName}
                          </td>
                          <td>{dateUtils.formatDateWithNoTimeZone(taskAssignment.assignedDate)}</td>
                          <td>{dateUtils.formatDateWithNoTimeZone(taskAssignment.statusChange)}</td>
                        </tr>
                      );
                    }) : 
                    <tr>
                      <td colSpan={6} className="text-center">
                        No records available
                      </td>
                      </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </CustomCard>
        </div>
      </div>
      {currentTaskAssignment && Object.entries(currentTaskAssignment).length > 0 ? (
        <div className="row">
          <div className="col">
            <CustomCard headerText={`Task Detail (${currentTaskAssignment?.taskNumber})`} collapsible={true}>
              <div className="row" id="taskpanel">
                <div className="col-lg-3">
                  <label htmlFor="taskNumber" className="font-weight-bold" title="Task Number">
                    Task Number:
                  </label>
                  <input
                    title="Task Number"
                    type="text"
                    name="taskNumber"
                    id="taskNumber"
                    value={currentTaskAssignment?.taskNumber}
                    readOnly
                    className="form-control"
                  />
                </div>
                {displayTaskType() && (
                  <div className="col-lg-3">
                    <label htmlFor="taskType" className="font-weight-bold">
                      Task Type:
                    </label>
                    <input
                      title="Task Type"
                      type="text"
                      name="taskType"
                      id="taskType"
                      value={currentTaskAssignment?.taskType}
                      readOnly
                      className="form-control"
                    />
                  </div>
                )}
                {!isConsultantTask && (
                  <div className="col-lg-3">
                    <label htmlFor="inspectorSelector" className="font-weight-bold" title="FDOT Inspector">
                      FDOT Inspector:
                    </label>
                    <select
                      disabled={isConsultantTask || isFieldConsultantManager}
                      title="FDOT Inspector"
                      className="form-control"
                      id="inspectorSelector"
                      value={assignedInspectorId ?? ""}
                      onChange={e => {
                        setAssignedInspectorId(e.target.value);
                        setAssignedConsultantInspectorId("");
                      }}>
                      {/* <InspectorOptions></InspectorOptions> */}
                      <>
                        <option value="">Select</option>
                        {inspectorsUserProfiles.map(inspector => {
                          return (
                            <option key={inspector.azureAdOid} value={inspector._id}>
                              {inspector.displayName} ({inspector.emailAddress})
                            </option>
                          );
                        })}
                      </>
                    </select>
                  </div>
                )}
                {isConsultantTask && (
                  <div className="col-lg-3">
                    <label
                      htmlFor="consultantInspectorSelector"
                      className="font-weight-bold"
                      title="Consultant Inspector">
                      Consultant Inspector:
                    </label>
                    <select
                      disabled={!canChangeConsultantInspector}
                      title="Consultant Inspector"
                      className="form-control"
                      id="consultantInspectorSelector"
                      name="consultantInspectorSelector"
                      value={assignedConsultantInspectorId ?? ""}
                      onChange={e => {
                        setAssignedConsultantInspectorId(e.target.value);
                        setAssignedInspectorId("");
                      }}>
                      <option value="">Select</option>
                      {fieldConsultantInspectors.map(inspector => {
                        return (
                          <option key={inspector.azureAdOid} value={inspector._id}>
                            {inspector.displayName} ({inspector.emailAddress})
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
                <div className="col-lg-3">
                  <label htmlFor="taskStatus" title="Status" className="font-weight-bold">
                    Status:
                  </label>
                  <select
                    title="Status"
                    name="taskStatus"
                    id="taskStatus"
                    onChange={e => setTaskStatus(e.target.value)}
                    value={taskStatus}
                    className="form-control"
                    disabled={!canEditTask}>
                    <EnumToOptions enumeration={TaskAssignmentStatus}></EnumToOptions>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <label htmlFor="assignedDate" title="Assigned Date" className="font-weight-bold">
                    Assigned Date:
                  </label>
                  <input
                    title="Assigned Date"
                    id="assignedDate"
                    type="text"
                    className="form-control"
                    value={dateUtils.formatDateWithNoTimeZone(currentTaskAssignment?.assignedDate)}
                    readOnly
                  />
                </div>
                <div className="col-lg-3">
                  <label htmlFor="assignedBy" title="Assigned By" className="font-weight-bold">
                    Assigned By:
                  </label>
                  <input
                    title="Assigned By"
                    id="assignedBy"
                    type="text"
                    className="form-control"
                    value={currentTaskAssignment?.assignedBy?.displayName}
                    readOnly
                  />
                </div>
                {currentTaskAssignment?.taskType === TaskType.VOC && (
                  <div className="col-lg-1">
                    <label htmlFor="voidOfCopy" title="Void of Copy?" className="font-weight-bold">
                      Void of Copy?:
                    </label>
                    <input
                      title="Void of Copy?"
                      type="Checkbox"
                      className="form-control-checkbox"
                      disabled={!canEditTask || isTaskComplete}
                      checked={voidOfCopy}
                      onChange={e => setVoidOfCopy(e.target.checked)}
                    />
                  </div>
                )}
                {currentTaskAssignment?.taskType === TaskType.Inspection270Day && (
                  <div className="col-lg-4">
                    <label htmlFor="inspectionResult" title="Inspection Result?" className="font-weight-bold">
                      Inspection Result:
                    </label>
                    <br />
                    <input
                      title={Inspection270DayResult.Built}
                      type="radio"
                      name="built"
                      className="form-control-rb"
                      value={Inspection270DayResult.Built}
                      checked={inspectionResult === Inspection270DayResult.Built}
                      disabled={!canEditTask || isTaskComplete}
                      onChange={e => {
                        setInspectionResult(Inspection270DayResult.Built);
                      }}
                    />
                    &nbsp;Structure has been built&nbsp;&nbsp;&nbsp;
                    <br />
                    <input
                      title={Inspection270DayResult.NotBuilt}
                      type="radio"
                      name="built"
                      className="form-control-rb"
                      value={Inspection270DayResult.NotBuilt}
                      checked={inspectionResult === Inspection270DayResult.NotBuilt}
                      disabled={!canEditTask || isTaskComplete}
                      onChange={e => {
                        setInspectionResult(Inspection270DayResult.NotBuilt);
                      }}
                    />
                    &nbsp;No structure built on permit site&nbsp;&nbsp;&nbsp;
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <label htmlFor="statusChangeDate" title="Completed Date" className="font-weight-bold">
                    Status Change Date:
                  </label>
                  <input
                    title="Completed Date"
                    id="statusChangeDate"
                    type="text"
                    className="form-control"
                    value={dateUtils.formatDateWithNoTimeZone(currentTaskAssignment?.statusChange)}
                    readOnly
                  />
                </div>
                <div className="col-lg-3">
                  <label htmlFor="dueDate" title="Completed Date" className="font-weight-bold">
                    Due Date:
                  </label>
                  <input
                    title="Completed Date"
                    id="dueDate"
                    type="date"
                    className="form-control"
                    disabled={!canEditDueDate}
                    onChange={e => {
                      console.log(e.target.value);
                      console.log(dateUtils.formatDateWithNoTimeZone(dueDate));
                      setDueDate(e.target.value);
                    }}
                    value={dueDate}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="actionRequired" title="Action Required" className="font-weight-bold">
                    Action Required:
                  </label>
                  <textarea
                    rows={3}
                    title="Action Required"
                    name="actionRequired"
                    id="actionRequired"
                    value={actionRequired}
                    disabled={!canEditTask || isTaskComplete}
                    onChange={e => setActionRequired(e.target.value)}
                    className="form-control"></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="taskComments" title="Inspector Comments" className="font-weight-bold">
                    Inspector Comments:
                  </label>
                  <textarea
                    rows={3}
                    title="Inspector Comments"
                    name="taskComments"
                    id="taskComments"
                    value={comment}
                    disabled={!canEditTask || isTaskComplete}
                    onChange={e => setComment(e.target.value)}
                    className="form-control"></textarea>
                </div>
              </div>
              <br />
              {canEditTask && (
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <button className="btn btn-primary" type="button" onClick={saveTaskAssignment}>
                      Save Task
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => dispatch(loadCurrentTaskAssignment({} as any))}>
                      Cancel
                    </button>
                  </div>
                </div>
              )}
              {!props.illegalSignId ? (
                <CustomCard
                  collapsible={true}
                  headerText={`Task Assignment (${currentTaskAssignment?.taskNumber}) Documents`}>
                  {!canEditTask ? (
                    <></>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-lg-6">
                          <label
                            htmlFor="documentType"
                            title="Document Type"
                            className={`font-weight-bold required-field`}>
                            Document Type
                          </label>
                          <DocumentTypeSelector
                            idSuffix="TaskAssignment"
                            applicableSections={[IApplicableSection.Tasks]}
                            isInternalUser={true}
                            isExternalUser={false}
                            selectedValue={documentType}
                            onChange={(selectedOption: string) => {
                              setDocumentType(selectedOption);
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label htmlFor="fileType" title="File Type">
                            <i>Accepted file types (Pdf, Images)</i>
                          </label>
                          <FilePond
                            server={{
                              process: {
                                url: taskAssignmentUrl(currentTaskAssignment?._id, documentType),
                                withCredentials: true,
                              },
                            }}
                            disabled={documentType === ""}
                            acceptedFileTypes={["image/*", "application/pdf"]}
                            allowRevert={false}
                            ref={filePondRef}
                            onprocessfile={(error, file) => {
                              if (!error) {
                                setTimeout(() => {
                                  (filePondRef.current as any).removeFile(file);
                                }, 1000);
                              }
                            }}
                            onprocessfiles={() => {
                              dispatch(getTaskAssignment(currentTaskAssignment?._id));
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {currentTaskAssignment && currentTaskAssignment?.documents ? (
                    [...currentTaskAssignment?.documents]
                      ?.sort((a, b) => {
                        return new Date(b.added).getTime() - new Date(a.added).getTime();
                      })
                      .map((doc, i) => {
                        return (
                          <div className="row" key={doc._id}>
                            <div className="col">
                              <a
                                title={`Download ${doc.originalname}`}
                                download={doc.originalname}
                                href={documentUrl(doc._id)}>
                                <DocumentLink doc={doc}></DocumentLink>
                              </a>{" "}
                            </div>

                            {!canEditTask ? (
                              <>
                                <div className="col">
                                  <ButtonWithIcon
                                    icon="trash"
                                    titleText="Remove Document"
                                    onClick={() => {
                                      if (window.confirm("Are you sure you want to delete this document?")) {
                                        removeAdditionalDocument(i);
                                      }
                                    }}
                                    buttonType="danger"></ButtonWithIcon>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      })
                  ) : (
                    <></>
                  )}
                </CustomCard>
              ) : (
                <></>
              )}
              <br />
            </CustomCard>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default TaskAssignment;
