"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getClosestBusinessDay = exports.formatForDateControl = exports.addDaysToDate = exports.weekdays = exports.addDays = exports.getSpecificTimeZoneDateString = exports.utcToISODateString = exports.sortDatesAsc = exports.addOffsetToDate = exports.formatDateWithNoTimeZone = exports.formatDateWithNoTimeZoneAddOffset = exports.getTimelessDateWithOffset = exports.removeTimeFromDate = exports.formatNullableDate = exports.inputFieldDateFormat = exports.getFiscalYearFromDate = void 0;
var date_fns_1 = require("date-fns");
exports.getFiscalYearFromDate = function (input) {
    var inputDate = new Date(input);
    var year = inputDate.getFullYear();
    var fiscalYearCutoff = new Date(year, 5, 30);
    if (inputDate > fiscalYearCutoff) {
        //first Half
        return year + "-" + (year + 1);
    }
    //second Half
    else
        return year - 1 + "-" + year;
};
exports.inputFieldDateFormat = function (date) {
    return exports.utcToISODateString(date);
};
exports.formatNullableDate = function (date) {
    if (date === undefined || date === null) {
        return "N/A";
    }
    var asDate = typeof date === "string" ? new Date(date) : date;
    return date_fns_1.format(asDate, "MM/dd/yyyy h:mm a");
};
exports.removeTimeFromDate = function (date) {
    var val = new Date(date);
    val.setHours(0, 0, 0, 0);
    return val;
};
//Note the grid filters require a date with no time in order to filter appropriately.
exports.getTimelessDateWithOffset = function (date, offset) {
    var offsetDate = exports.addOffsetToDate(date);
    if (!offsetDate) {
        return date;
    }
    var asDate = new Date(offsetDate);
    return exports.removeTimeFromDate(asDate);
};
exports.formatDateWithNoTimeZoneAddOffset = function (date, offset) {
    if (date === undefined || date === null || date === "") {
        return "N/A";
    }
    var asDate = new Date(date);
    try {
        //get difference between UTC and the current interpreted system date
        var calcOffset = asDate.getTimezoneOffset();
        var offsetDate = date_fns_1.addMinutes(asDate, offset ? offset * 60 : calcOffset);
        return date_fns_1.format(offsetDate, "MM/dd/yyyy");
    }
    catch (e) {
        console.log("Date: " + date + ", asDate: " + new Date(date));
        console.log(e);
        return "N/A";
    }
};
exports.formatDateWithNoTimeZone = function (date) {
    if (date === undefined || date === null || date === "") {
        return "N/A";
    }
    var asDate = typeof date === "string" ? new Date("" + date) : date;
    try {
        return date_fns_1.format(asDate, "MM/dd/yyyy");
    }
    catch (e) {
        console.log("Date: " + date + ", asDate: " + new Date(date));
        console.log(e);
        return "N/A";
    }
};
exports.addOffsetToDate = function (date, offset) {
    if (date === undefined || date === null || date === "") {
        return date;
    }
    var asDate = new Date(date);
    try {
        //get difference between UTC and the current interpreted system date
        var calcOffset = asDate.getTimezoneOffset();
        var offsetDate = date_fns_1.addMinutes(asDate, offset ? offset * 60 : calcOffset);
        return offsetDate;
    }
    catch (e) {
        console.log("Date: " + date + ", asDate: " + new Date(date));
        console.log(e);
        return date;
    }
};
exports.sortDatesAsc = function (x, y) {
    var a = new Date(x).getTime(), b = new Date(y).getTime();
    return a - b;
};
exports.utcToISODateString = function (value) {
    if (value === "" || value === null || value === undefined) {
        return "";
    }
    var inputDate = typeof value === "string" ? new Date(value) : value;
    try {
        return inputDate.toISOString().slice(0, 10);
    }
    catch (ex) {
        return "";
    }
};
exports.getSpecificTimeZoneDateString = function (value, timeZone) {
    if (value === "" || value === null || value === undefined) {
        return "";
    }
    var inputDate = typeof value === "string" ? new Date(value) : value;
    try {
        return inputDate.toLocaleDateString("en-US", { timeZone: timeZone });
    }
    catch (ex) {
        return "";
    }
};
exports.addDays = function (value, days) {
    if (value === "" || value === null || value === undefined) {
        return null;
    }
    var inputDate = typeof value === "string" ? new Date(value) : value;
    var date = new Date(inputDate.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};
exports.weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
exports.addDaysToDate = function (date, daysToAdd) {
    var val = new Date(date);
    val.setDate(val.getDate() + daysToAdd);
    return val;
};
exports.formatForDateControl = function (date) {
    if (!date)
        return "";
    var _a = date.substr(0, 10).split("-"), year = _a[0], month = _a[1], day = _a[2];
    if (parseInt(year) > 999) {
        return date_fns_1.format(new Date(year, month - 1, day), "yyyy-MM-dd");
    }
    return date;
};
exports.getClosestBusinessDay = function (input) {
    var weekday = input.getDay();
    if (weekday === 0) {
        //Sunday - shift to Monday
        return date_fns_1.addDays(input, 1);
    }
    else if (weekday === 6) {
        //Saturday - shift to Friday
        return date_fns_1.subDays(input, 1);
    }
    return input;
};
