import * as litigationApi from "../../apis/LitigationApi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILitigation, ILitigationRow, dateUtils } from "oda-shared";

type SliceState = {
  litigation: ILitigation | null;
  isLoading: boolean;
  litigationsForExcel: ILitigationRow[];
};

const reFormatDates = (litigation: ILitigation) => {
  litigation.petitionFiledOn = dateUtils.inputFieldDateFormat(litigation.petitionFiledOn) as any;
  litigation.programDueDate = dateUtils.inputFieldDateFormat(litigation.programDueDate) as any;
  litigation.referredToDoah = dateUtils.inputFieldDateFormat(litigation.referredToDoah) as any;
};

const litigationSlice = createSlice({
  name: "litigation",
  initialState: {
    litigation: null,
    isLoading: false,
    litigationsForExcel: [],
  } as SliceState,
  reducers: {
    updateLitigation: (state: SliceState, action: PayloadAction<ILitigation | null>) => {
      state.litigation = action.payload;
      if (state.litigation) {
        reFormatDates(state.litigation);
      }
      return state;
    },
    updateIsLoading: (state: SliceState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      return state;
    },
    updateLitigationRowsForExcel: (state: SliceState, action: PayloadAction<ILitigationRow[]>) => {
      state.litigationsForExcel = action.payload;
      return state;
    },
  },
});

export const { updateLitigation, updateIsLoading, updateLitigationRowsForExcel } = litigationSlice.actions;
export default litigationSlice.reducer;

export const fetchLitigation = (id: string) => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  litigationApi
    .fetchLitigation(id)
    .then((litigation: ILitigation) => {
      dispatch(updateLitigation(litigation));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};
export const fetchLitigationsForExcel = () => async (dispatch: any) => {
  dispatch(updateIsLoading(true));
  litigationApi
    .getLitigationsForExcel()
    .then((litigations: ILitigationRow[]) => {
      dispatch(updateLitigationRowsForExcel(litigations));
      dispatch(updateIsLoading(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateIsLoading(false));
    });
};

export const editLitigation =
  (id: string, litigation: ILitigation, successCallback: () => void, failureCallback: () => void) =>
    async (dispatch: any) => {
      dispatch(updateIsLoading(true));
      litigationApi
        .updateLitigation(litigation, id)
        .then((updatedLitigation: ILitigation) => {
          dispatch(updateLitigation(updatedLitigation));
          dispatch(updateIsLoading(false));
          successCallback();
        })
        .catch((error: any) => {
          console.log(error);
          dispatch(updateIsLoading(false));
          failureCallback();
        });
    };

export const createLitigation =
  (litigation: ILitigation, successCallback: (id: string) => void, failureCallback: () => void) =>
    async (dispatch: any) => {
      dispatch(updateIsLoading(false));
      litigationApi
        .createLitigation(litigation)
        .then((newLit: ILitigation) => {
          dispatch(updateLitigation(litigation));
          dispatch(updateIsLoading(false));
          successCallback(newLit._id);
        })
        .catch((error: any) => {
          console.log(error);
          dispatch(updateIsLoading(false));
          failureCallback();
        });
    };
