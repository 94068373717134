import React, { useEffect, useState } from "react";
import { CustomCard } from "../common/CustomCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchPermitActivityForAccountData } from "./../../redux/slices/ReportSlice";
import { AccountSelector } from "../common/AccountSelector";
import { getAccountsForUser } from "../../redux/slices/OdaAccountSlice";
import { PermitActivityRange } from "oda-shared";
import {dateUtils} from "oda-shared";
import { Link } from "react-router-dom";

const PermitActivityForAccount: React.FC = () => {
  const dispatch = useDispatch();
  const permitActivityByAccountResults = useSelector(
    (state: any) => state.reportReducer.permitActivityByAccountResults,
  );
  const [selectedAccount, setSelectedAccount] = useState<any>(null);
  const [range, setRange] = useState<any>();

  useEffect(() => {
    dispatch(getAccountsForUser({}));
  }, [dispatch]);

  return (
    <div className="container-fluid">
      <CustomCard headerText="Permit Activity for Account Criteria">
        <div className="row">
          <div className="col-2 font-weight-bold">
            <label htmlFor="accountId">Account:</label>
          </div>
          <div className="col-lg-4">
            <AccountSelector
              id="accountId"
              selectedAccount={selectedAccount}
              restrictToUserRoles={false}
              onChange={selected => {
                setSelectedAccount(selected);
              }}></AccountSelector>
          </div>
        </div>
        <div className="row">
          <div className="col-2 font-weight-bold">
            <label htmlFor="selectRange">Time Period:</label>
          </div>
          <div className="col-lg-4">
            <select
              id="selectRange"
              value={range}
              className="form-control"
              onChange={e => {
                setRange(e.target.value);
              }}>
              <option value={""}>Select Range</option>
              <option value={PermitActivityRange.SinceLastAnnualBilling}>
                {PermitActivityRange.SinceLastAnnualBilling}
              </option>
              <option value={PermitActivityRange.ThirtyDays}>{PermitActivityRange.ThirtyDays}</option>
              <option value={PermitActivityRange.NinetyDays}>{PermitActivityRange.NinetyDays}</option>
              <option value={PermitActivityRange.OneHundredEightyDays}>
                {PermitActivityRange.OneHundredEightyDays}
              </option>
              <option value={PermitActivityRange.TwoHundredSeventyDays}>
                {PermitActivityRange.TwoHundredSeventyDays}
              </option>
              <option value={PermitActivityRange.OneYear}>{PermitActivityRange.OneYear}</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button
              className="btn btn-primary"
              onClick={() => {
                dispatch(fetchPermitActivityForAccountData(selectedAccount._id, range));
              }}
              disabled={!range || !selectedAccount}>
              Search
            </button>
          </div>
        </div>
      </CustomCard>
      <br />
      <CustomCard headerText={`Permit Activity For Account Results`}>
        <div className="row">
          <div className="col">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Tag Number</th>
                  <th>Permit Status</th>
                  <th>Status Date</th>
                  <th>Section Number</th>
                  <th>Milepost</th>
                  <th>Side of Road</th>
                  <th>Sign Read</th>
                </tr>
              </thead>
              <tbody>
                {permitActivityByAccountResults.map((item: any, i: number) => (
                  <tr key={i}>
                    <td>
                      <Link to={`/SignDetail/${item.tagNumber}`}>{item.tagNumber}</Link>
                    </td>
                    <td>{item.status}</td>
                    <td>{dateUtils.formatDateWithNoTimeZone(item.statusDate)}</td>
                    <td>
                      <Link to={`/Search/Section/${item.sectionId}`}>{item.sectionId}</Link>
                    </td>
                    <td>{item.milepost}</td>
                    <td>{item.sideOfSection}</td>
                    <td>{item.signReads}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </CustomCard>
    </div>
  );
};

export default PermitActivityForAccount;
